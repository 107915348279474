import { sharedStorageKeys } from "@divide/retailsuite-react-framework/constants";

const storageKeys = {
    addToHomeScreenMessageIsClosed: "prt_add-to-home-screen-message-is-closed",
    date: "prt_date",
    hasUsedHomeScreenApp: "prt_has-used-home-screen-app",
    hasUsedHomeScreenAppMessagesIsClosed: "prt_has-used-home-screen-message-is-closed",
    updateAvailable: "prt_update_available",
    version: "prt_version",
    ...sharedStorageKeys
};

export default storageKeys;