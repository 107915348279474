import { lazy, Suspense } from "react";
import { sharedRoutes } from "./sharedRoutes";

const LoginPage = lazy(() => import("pages/LoginPage"));
const Invitation = lazy(() => import("pages/Invitation"));

export const publicRoutes = [
    {
        path: "/",
        element: <Suspense fallback={<></>}><LoginPage /></Suspense>,
        children: [],
    },
    {
        path: "/login",
        element: <Suspense fallback={<></>}><Invitation /></Suspense>,
        children: []
    },
    ...sharedRoutes
];