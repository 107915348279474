const frFr = {
    global: {
        forgotPassword: "Mot de passe oublié?",
        forgotPasswordContact: "Avez-vous oublié votre mot de passe? Veuillez contacter le service Digital Sales Assistant.",
        retailSuiteApps: "Applications Retail Suite",
        signIn: "S'identifier",
        tipsAndTricks: "Conseils & Astuces",
        welcomePortal: "Bienvenue sur le portail.",
    },
    tipsTricks: {
        nhg: {
            subject: "NHG",
            teaser: "NHG Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dans ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
        dsa: {
            subject: "AVD",
            teaser: "DSA Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dans ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
        oc: {
            subject: "CO",
            teaser: "OC orem ipsum dolor sit amet, consectetur adipiscing elit. Dans ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
    },
    messages: {
        loading: {
            default: "Chargement...",
        },
        success: {
            invitationReceivedForOrganization: (name) => `L'invitation pour ${name} a été traitée, cliquez sur "Connexion" pour continuer.`,
        },
        error: {
            organizationNameAndOrInvitationWasNotProvided: "Le nom de l'organisation et/ou l'invitation n'ont pas été précisés.",
            somethingWentWrongProcessingInvitation: "Une erreur s'est produite lors du traitement de l'invitation.",
            organizationNotFound: (name) => `L'organisation ${name} est introuvable.`,
            inviteNotFoundOrAlreadyUsed: "L'invitation est introuvable ou a déjà été utilisée."
        },
    },
};

export default frFr;
