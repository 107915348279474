import { lazy, Suspense } from "react";
import { sharedRoutes } from "./sharedRoutes";

const Home = lazy(() => import("pages/Home"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));

export const privateRoutes = [
    {
        path: "/",
        element: <Suspense fallback={<></>}><Home /></Suspense>,
        children: [],
    },
    {
        path: "/profile",
        element: <Suspense fallback={<></>}><ProfilePage /></Suspense>,
        children: [],
    },
    ...sharedRoutes
]