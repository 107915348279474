const nlNl = {
    global: {
        forgotPassword: "Wachtwoord vergeten?",
        forgotPasswordContact: "Bent u uw wachtwoord vergeten? Neem contact op met de Digital Sales Assistant service.",
        retailSuiteApps: "Retail Suite apps",
        signIn: "Inloggen",
        tipsAndTricks: "Tips & tricks",
        welcomePortal: "Welkom op het portaal.",
    },
    tipsTricks: {
        nhg: {
            subject: "NHG",
            teaser: "NHG Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementum cursus purus justo id felis. Curabitur.",
        },
        dsa: {
            subject: "DSA",
            teaser: "DSA Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementum cursus purus justo id felis. Curabitur.",
        },
        oc: {
            subject: "OC",
            teaser: "OC orem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementum cursus purus justo id felis. Curabitur.",
        },
    },
    messages: {
        loading: {
            default: "Laden...",
        },
        success: {
            invitationReceivedForOrganization: (name) => `De uitnodiging voor ${name} is verwerkt, klik op "inloggen" om verder te gaan.`,
        },
        error: {
            organizationNameAndOrInvitationWasNotProvided: "De organisatienaam en/of uitnodiging is niet opgegeven.",
            somethingWentWrongProcessingInvitation: "Er is iets misgegaan bij het verwerken van de uitnodiging.",
            organizationNotFound: (name) => `De organisatie ${name} kon niet gevonden worden.`,
            inviteNotFoundOrAlreadyUsed: "De uitnodiging is kon niet gevonden worden of is al gebruikt."
        },
    },
};

export default nlNl;
