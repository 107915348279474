const deDe = {
    global: {
        forgotPassword: "Passwort vergessen?",
        forgotPasswordContact: "Haben Sie Ihr Passwort vergessen? Wenden Sie sich bitte an den Digital Sales Assistant-Service.",
        retailSuiteApps: "Retail Suite-Apps",
        signIn: "Einloggen",
        tipsAndTricks: "Tipps",
        welcomePortal: "Willkommen auf dem Portal.",
    },
    tipsTricks: {
        nhg: {
            subject: "NHG",
            teaser: "NHG Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
        dsa: {
            subject: "DSA",
            teaser: "DSA Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
        oc: {
            subject: "OK",
            teaser: "OC orem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
    },
    messages: {
        loading: {
            default: "Wird geladen...",
        },
        success: {
            invitationReceivedForOrganization: (name) => `Die Einladung für ${name} wurde bearbeitet. Klicken Sie auf "Anmelden", um fortzufahren.`,
        },
        error: {
            organizationNameAndOrInvitationWasNotProvided: `Der Name der Organisation und/oder die Einladung wurde nicht angegeben.`,
            somethingWentWrongProcessingInvitation: `Beim Verarbeiten der Einladung ist ein Fehler aufgetreten.`,
            organizationNotFound: (name) => `Die Organisation ${name} konnte nicht gefunden werden.`,
            inviteNotFoundOrAlreadyUsed: `Die Einladung konnte nicht gefunden werden oder wurde bereits verwendet.`
        },
    },
};

export default deDe;
