const enGb = {
    global: {
        forgotPassword: "Forgot password?",
        forgotPasswordContact: "Did you forget your password? Please contact the Digital Sales Assistant service.",
        retailSuiteApps: "Retail Suite apps",
        signIn: "Sign in",
        tipsAndTricks: "Tips & tricks",
        welcomePortal: "Welcome to the portal.",
    },
    tipsTricks: {
        nhg: {
            subject: "NHG",
            teaser: "NHG Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
        dsa: {
            subject: "DSA",
            teaser: "DSA Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
        oc: {
            subject: "OC",
            teaser: "OC orem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
    },
    messages: {
        loading: {
            default: "Loading...",
        },
        success: {
            invitationReceivedForOrganization: (name) => `The invitation for ${name} has been processed, click "login" to continue.`,
        },
        error: {
            organizationNameAndOrInvitationWasNotProvided: "The organization name and/or invitation was not specified.",
            somethingWentWrongProcessingInvitation: "Something went wrong while processing the invitation.",
            organizationNotFound: (name) => `The organization ${name} could not be found.`,
            inviteNotFoundOrAlreadyUsed: "The invitation could not be found or has already been used."
        },
    },
};

export default enGb;
