const env = process.env;

const auth0Config = {
    domain: "divide.eu.auth0.com",
    scopes: "profile openid email admin chooseBranch get:branches get:configuration get:settings get:endpoints",
    clientId: env.REACT_APP_AUTH0_CLIENT_ID,
    audience: env.REACT_APP_AUTH0_AUDIENCE
};

export default auth0Config;
